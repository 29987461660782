import { authExchange as urqlAuthExchange } from '@urql/exchange-auth';
import { hasAuthenticationError } from './AuthenticationError.js';
export const authExchange = (authConfigurator) => {
    const init = typeof authConfigurator === 'function' ? authConfigurator : () => authConfigurator;
    return urqlAuthExchange(init);
};
// GraphQLYoga returns an extension of "{ http: { status: number } }" for HTTP errors.
// https://the-guild.dev/graphql/yoga-server/docs/features/error-masking#modifying-http-status-codes-and-headers
function* getHttpStatusOrCodesFromError(e) {
    const extensions = e.extensions;
    if (!extensions) {
        return;
    }
    if ('code' in extensions) {
        yield extensions['code'];
    }
    if ('http' in extensions && typeof extensions['http'] === 'object' && extensions['http'] != null) {
        if ('status' in extensions['http']) {
            yield extensions['http']['status'];
        }
    }
}
const AUTH_ERROR_CODES = ['401', 'UNAUTHORIZED', '403', 'FORBIDDEN'];
const isAuthErrorCode = (code) => code && AUTH_ERROR_CODES.includes(String(code).toUpperCase());
export const hasAuthLikeError = (error) => (error.networkError && hasAuthenticationError(error.networkError)) ||
    error.graphQLErrors.some(e => Array.from(getHttpStatusOrCodesFromError(e)).some(code => isAuthErrorCode(code)));
